import React from 'react'
import { graphql } from 'gatsby'


import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const ContactPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Contact Us" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-secondary">
      <section>
        <p></p>
        <p>Howell Farms is located approximately 40 miles northeast of Indianapolis. We are reachable via telephone at the number below. Also be sure to <a href="https://www.facebook.com/howellfarmsmiddletown" target="_blank" rel="noopener noreferrer">connect with us on Facebook</a>.</p>
        <address>
          <span><em>Howell Farms of Crossroads LLC</em></span>
          <span>12261 S Co Rd 600 W</span>
          <span>Middletown, IN 47356</span>
          <span><em>Phone:</em> <a href="tel:17657597432">(765) 759-7432</a></span>
        </address>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.667508469543!2d-85.50174678460657!3d40.082828083802696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x881525c0a71bf823%3A0x174d3a0709a9b1d9!2sHowell+Farms!5e0!3m2!1sen!2sus!4v1559584717045!5m2!1sen!2sus" className="googleMap" title="Map" allowfullscreen></iframe>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "contact-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ContactPage
